import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import qs from 'query-string'
import api from 'services/api'
import { comma } from 'services/utils'
import moment from 'services/moment'

import { Importer, Exporter } from 'services/transform'
import invoiceExportParser from './export.invoice'
import invoice2uExportParser from './export.invoice-2u'
import printFileExportParser from './export.printFile'
import printFile2uExportParser from './export.printFile-2u'

import Dropdown from './Dropdown'
import Sorts from './Sorts'

import { Header, Task, Items, DataSet, Lnr, Pager, Caret, Copiable, ProgressModal } from './utils'


const List = ({
  user, location, history, match, error,
  title, nav, type,
  keywordsOption, keywords, filters, sorts, items: oItems, selecteds,
  total, page, limit, blockLimit,
  startAt, endAt,
  sortOptions, filterOptions, keywordsOptions,
  initialize, handle, loadItems
}) => {

  // 편집을 위해서 랩핑을 해준다.
  const items = oItems.map(item => {
    const obj = { ...item }
    obj.editAmount = typeof obj.editAmount === 'undefined' ? item.amount : obj.editAmount
    return obj
  })

  const commonProps = { user, location, history, match }

  const isSelectedAll = () => items.length === selecteds.length ? true : false
  const isExistsSelected = (item) => selecteds.find(_item => _item.id === item.id) ? true : false

  // 전체 선택
  const checkAll = () => {
    if (!items.length) { return null }
    const prev = selecteds || []
    const pIdxs = prev.map(item => item.id), cIdxs = items.map(item => item.id)
    const next = [...prev.filter(item => !cIdxs.includes(item.id)), ...items.filter(item => !pIdxs.includes(item.id))]
    return handle({ selecteds: next })
  }

  // 선택한 한가지 데이터
  const doCheck = (item) => {
    const prev = selecteds, next = []
    if (prev.find(_item => _item.id === item.id)) {
      next.push(...prev.filter(_item => _item.id !== item.id))
    } else {
      next.push(...prev, item)
    }
    return handle({ selecteds: next })
  }

  // 컬럼 구성
  // 도서ID, 도서명, ISBN번호, 용도, 출판사, 저자명, 주문건수, 주문수량, 재고량, 우선배정량, 수령예정량, 예정잔량,발주량, 발주추가, 삭제
  const columns = []
  columns.push({
    key: 'checkbox', header: <input type="checkbox" checked={isSelectedAll()} onChange={e => checkAll()} />,
    style: { minWidth: '60px', maxWidth: '60px', justifyContent: 'center' }
  })
  columns.push({ key: 'TypeColumn', header: '구분', style: { minWidth: '50px', maxWidth: '50px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'NoColumn', header: '도서번호', style: { minWidth: '100px', maxWidth: '100px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'PurposeColumn', header: '출판용도', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'LegalColumn', header: '납본여부', style: { minWidth: '120px', maxWidth: '120px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'DetailColumn', header: '도서정보', style: { minWidth: '418px', maxWidth: '418px', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'TextColorColumn', header: '내지', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ReadyParcelOrderAmountColumn', header: '주문량', style: { minWidth: '90px', maxWidth: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center' } })
  columns.push({ key: 'EnabledStockAmountColumn', header: '보유량', style: { minWidth: '90px', maxWidth: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center' } })
  columns.push({ key: 'InvoiceStockAmountColumn', header: '수령예정', style: { minWidth: '90px', maxWidth: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center' } })
  columns.push({ key: 'AmountColumn', header: '발주량', style: { minWidth: '90px', maxWidth: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center' } })
  columns.push({ key: 'ActionColumn', header: '변경', style: { minWidth: '160px', maxWidth: '160px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'BlankColumn', header: '', style: { flex: '1 1 auto', minWidth: 0, maxWidth: '100%', justifyContent: 'flex-start', display: 'flex', alignItems: 'center' } })
  columns.push({ key: 'ControlColumn', header: '삭제', style: { minWidth: '80px', maxWidth: '80px', justifyContent: 'center', display: 'flex', alignItems: 'center' } })

  // 설정부 모달에 대한 관리
  const [modal, setModal] = React.useState(false)

  const [pending, setPending] = React.useState(false)

  // ProgressModal 컴포넌트를 활용하기 위한 Hooks
  const [processed, setProcessed] = React.useState(false)
  const [progressModal, setProgressModal] = React.useState(false)

  // 상품 타입에 대한 설정
  const parcelTypes = {}
  parcelTypes.paperBook = { name: 'paperBook', text: '종이도서' }
  parcelTypes.electronicBook = { name: 'electronicBook', text: '전자도서' }
  parcelTypes.solution = { name: 'solution', text: '작가서비스' }
  parcelTypes.etc = { name: 'etc', text: '기타' }

  // 결제방법 구분
  const payMethodTypes = {}
  payMethodTypes.card = { name: 'card', text: '신용카드' }
  payMethodTypes.card_rebill = { name: 'card_rebill', text: '정기결제' }
  payMethodTypes.card_rebill_rest = { name: 'card_rebill_rest', text: '정기결제 (REST API)' }
  payMethodTypes.phone = { name: 'phone', text: '휴대폰결제' }
  payMethodTypes.bank = { name: 'bank', text: '계좌이체' }
  payMethodTypes.vbank = { name: 'vbank', text: '가상계좌' }
  payMethodTypes.kakao = { name: 'kakao', text: '카카오페이 간편결제' }
  payMethodTypes.payco = { name: 'payco', text: '페이코' }
  payMethodTypes.npay = { name: 'npay', text: '네이버페이 간편결제' }
  payMethodTypes.free = { name: 'free', text: '무료' }
  payMethodTypes.etc = { name: 'etc', text: '기타' }

  // 로우 작동 액션들 : 수령처리, 삭제처리, 선택수령기능, 선택삭제 기능
  const actions = {}

  // 오늘 발행했던 인보이스 복원해서 보기
  actions.doRollbackInvoices = async () => {
    if (pending) { return alert('잠시만 기다려주세요.') }
    setPending(true)
    const result = await api.get(`/invoices/admin2/rollbackInvoices`, {}).catch(e => null)
    alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`)
    return initialize()
  }

  // 캐시파일 재생성 기능
  actions.doRefreshInvoices = async () => {
    if (pending) { return alert('잠시만 기다려주세요.') }
    setPending(true)
    const result = await api.put(`/invoices/admin2/refreshInvoices`, {}).catch(e => null)
    alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`)
    return initialize()
  }

  // 첫 페이지 데이터를 가져오고, 데이터를 나누고나서 추가 페이지 데이터를 더 가져오기
  actions.getItemsAll = async (query = {}, options = {}) => {
    const lump = query.limit || limit || 30
    const output = await loadItems({ ...query, limit: lump }, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
    if (!output.count) { return [] }
    const pageCount = Math.ceil(output.count/lump)
    if (pageCount === 1) { return output.rows }
    return await Array.from({ length: pageCount - 1 })
      .map((b, idx) => (idx + 2))
      .reduce((prev, page, idx, arr) => 
        prev.then(async (rows) => {
          if (options.progress) { setProgressModal({ message: `${idx+1}/${arr.length} 블록으로 나누어 다운로드중...`, rate: ((idx + 1)/arr.length) * 100, onClose: () => setProgressModal(null) }) }
          const _query = { ...query }
          _query.usedCount = false // 조금이나마 더 빠르게 불러오기
          _query.limit = lump // 페이지 개수 통일
          _query.page = page // 페이지 번호 전달
          const output = await loadItems(_query, { returnal: true }).catch(e => ({ rows: [], count: 0 }))
          if (!output || !_.get(output, 'rows.length')) { return rows }
          rows.push(...output.rows)
          return rows
        }),
        Promise.resolve(output.rows))
      .then((rows) => {
        if (options.progress) { setProgressModal(null) }
        return rows
      })
      .catch(e => {
        if (options.progress) { setProgressModal(null) }
        return []
      })
  }
  
  // 선택한 내역으로 발주서를 엑셀 만드는 기능
  actions.doDownloadInvoiceExcel = async (items = selecteds, mode = '4u') => {
    if (!items.length) { return alert(`선택한 신규발주내역이 존재하지 않습니다.`) }
    if (!window.confirm(`해당 발주내역(총 ${items.length}건)을 발주서 엑셀로 변환해서 다운로드 할까요?`)) { return }
    const curAt = new Date(), curMt = moment(curAt)

    // 수기, 자동 합산처리
    const distinctItems = JSON.parse(JSON.stringify(items))
      .reduce((s, item = {}) => {
        const existsItem = _.get(s, item.product)
        const combined = existsItem || item
        if (existsItem) { combined.amount = combined.amount + item.amount }
        _.set(s, item.product, combined)
        return s
      }, {})
    const targets = Object.values(distinctItems)

    // @ 포멧터 2가지 구성, 2023.08.29
    const parser = ['2u'].includes(mode)
      ? Object.assign({}, invoice2uExportParser)
      : Object.assign({}, invoiceExportParser)

    // const totalCount = targets.length
    // const totalAmount = targets.reduce((s, target) => s + target.amount, 0)

    return await new Exporter({ parser, items: targets })
      .download(`${curMt.format('YYYY-MM-DD')}`) // 발주엑셀(내역) 총 ${totalCount}종 ${totalAmount}부 - 
  }

  /*
    [A5, 46판]
    발주량 4 이하,  4로 나눠서 나머지 값이 0이 아니라면 [ CS ]
    발주량 4 이하,  4로 나눠서 나머지 값이 0이라면 [ IMP ]
    발주량 5 이상,  4로 나눠서 나머지 값이 0이 아니라면  혼합 [IMP , CS]
    발주량 5 이상,  4로 나눠서 나머지 값이 0이라면 [ IMP ]

    [A4, B5]
    발주량 2 이하,  2로 나눠서 나머지 값이 0이 아니라면  [ CS ]
    발주량 2 이하,  2로 나눠서 나머지 값이 0이라면 [ IMP ]
    발주량 3 이상,  2로 나눠서 나머지 값이 0이 아니라면 혼합 [IMP , CS]
    발주량 3 이상,  2로 나눠서 나머지 값이 0이라면 [ IMP ]
  */
  // 선택한 내역으로 원고엑셀 다운로드 기능을 만들기
  actions.doDownloadPrintFileExcel = async (items = selecteds, mode = '4u') => {
    if (!items.length) { return alert(`선택한 신규 발주내역이 존재하지 않습니다.`) }
    if (!window.confirm(`해당 발주내역(총 ${items.length}건)을 원고(발주량에 따른 CS,IMP 구분)엑셀로 다운로드 할까요?`)) { return }
    const curAt = new Date(), curMt = moment(curAt)
    
    // 수기, 자동 합산처리
    const distinctItems = JSON.parse(JSON.stringify(items))
      .reduce((s, item = {}) => {
        const existsItem = _.get(s, item.product)
        const combined = existsItem || item
        if (existsItem) { combined.amount = combined.amount + item.amount }
        _.set(s, item.product, combined)
        return s
      }, {})
    const targets = Object.values(distinctItems)

    const totalCount = targets.length
    const totalAmount = targets.reduce((s, target) => s + target.amount, 0)

    // @ 포멧터 2가지 구성, 2023.08.29
    const parser = ['2u'].includes(mode)
      ? printFile2uExportParser
      : printFileExportParser

    return await new Exporter({ parser, items: targets })
      .download(`발주엑셀(원고) 총 ${totalCount}종 ${totalAmount}부 - ${curMt.format('YYYY년 MM월 DD일 HH시 mm분')}`)
  }

  // 납본주문서 만들기 기능
  actions.doCreateLegalDepositPay = async (item = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`해당 내역을 납본 주문할까요?`)) { return }

    const identity = _.get(item, 'extras.currentProduct.id')

    const form = {}
    const result = await api.post(`/products/admin2/${identity}/legalDepositPay`, { from: item, form }).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`처리하였습니다.`)
      return initialize()
    }
    return result
  }

  // 선택내역 납본주문 만들기 기능
  actions.doCreateLegalDepositPayBySelecteds = async (items = selecteds) => {
    // 중복항 제거, 빈값 제거
    const distinctItems = JSON.parse(JSON.stringify(items))
      .reduce((s, item = {}) => {
        const identity = _.get(item, 'extras.currentProduct.id')
        const existsItem = _.get(s, identity)
        const combined = existsItem || item
        _.set(s, identity, combined)
        return s
      }, {})
    const targets = Object.values(distinctItems)

    const checked = await api.get(`/products/admin2/checkLegalDepositSystem`).catch(e => null)
    if (!checked || checked.error) { return alert((checked && checked.message) ? `${checked.message}` : `오류가 발생하였습니다.`) }

    if (!targets.length) { return alert(`선택한 상품이 존재하지 않습니다.`) }

    // 해당도서중에 전자도서가 있는지 체크한다.
    const electroincBookItems = targets.filter(item => ['electronicBook'].includes(_.get(item, 'extras.currentProduct.productType')))
    if (electroincBookItems.length) { return alert(`선택된 내역중에 전자도서가 있습니다. 전자도서 제외하고 선택해주세요. (${electroincBookItems.length}건)`) }

    // 해당 내역중 납본완료내역이 이미 있다면 오류 뱉어준다.
    const resolvedItems = targets.filter(item => ['resolved'].includes(_.get(item, 'extras.currentProduct.content.legalDeposit.status')))
    if (resolvedItems.length) { return alert(`선택된 내역중에 이미 납본완료된건이 존재합니다.납본이 완료된 건은 체크를 풀어주세요.(${resolvedItems.length}건)`) }

    // 해당 내역중 납본완료내역이 이미 있다면 오류 뱉어준다.
    const hasNotIsbnItems = targets.filter(item => !_.get(item, 'extras.currentProduct.content.isbn'))
    if (hasNotIsbnItems.length) { return alert(`선택된 내역중에 ISBN이 존재하지 않는 도서가 있습니다.(${hasNotIsbnItems.length}건)`) }

    if (!window.confirm(`해당 상품(총 ${targets.length}건)을 납본주문(2부 씩 배정) 처리할까요?`)) { return }

    const result = await targets.reduce((prev, item, idx) => prev.then(async (report) => {
      const result = await actions.doCreateLegalDepositPay(item, { returnal: true }).catch(e => null)
      if (result && result.error) {
        report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
        return report
      }
      report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
      return report
    }), Promise.resolve({ success: [], failure: [] }))

    if (result.failure.length) { alert(`성공 ${result.success.length}건, 실패 ${result.failure.length}건이 발생하였습니다.`) }
    if (!result.failure.length) { alert(`${result.success.length}건이 성공적으로 처리하였습니다.`) }
    return initialize()
  }

  // 발주량 변경기능
  actions.doUpdateInvoiceAmount = async (item = {}) => {
    if (!window.confirm(`발주량을 변경할까요?`)) { return }
    const form = {}
    form.amount = item.editAmount
    const result = await api.put(`/invoices/admin2/${item.id}/invoiceAmount`, { form }).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }

  // 발주요청서 삭제 기능
  actions.doDeleteInvoice = async (item ={}) => {
    if (!window.confirm(`발주 요청서를 삭제할까요?`)) { return }
    const result = await api.delete(`/invoices/admin2/${item.id}/invoice`).catch(e => null)
    if (!result || result.error) { return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    return initialize()
  }
  
  // 도서번호로 발주요청서를 추가하는 기능(수기내역 추가)
  actions.doCreateInvoiceByBookNo = async () => {
    const bookNo = window.prompt(`도서번호를 입력해주세요.`)
    if (!bookNo) { return }
    if (!_.isInteger(bookNo * 1)) { return alert(`숫자만 입력이 가능합니다.`) }

    const amount = window.prompt(`필요수량을 입력해주세요.`)
    if (!amount) { return alert(`도서번호가 미입력 되었습니다.`) }
    if (!_.isInteger(amount * 1)) { return alert(`숫자만 입력이 가능합니다.`) }

    const form = {}
    form.amount = amount

    const result = await api.post(`/invoices/admin2/invoiceByBookNo`, { bookNo, form }).catch(e => null)
    if (!result || result.error) {
      return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`)
    }

    return initialize()
  }

  // 단일로 발주하는 기능
  actions.doCreateQuantity = async (item = {}, opts = {}) => {
    if (!opts.returnal && !window.confirm(`해당 주문내역을 발주처리 전환 할까요?`)) { return }
    const form = {}
    form.classify = item.manual ? `overflow` :  `invoice`
    form.product = item.product
    form.total = item.amount
    const result = await api.post(`/invoices/admin2/invoiceQuantity`, { from: item, form }).catch(e => null)
    if (!result || result.error) {
      if (!opts.returnal){ return alert((result && result.message) ? `${result.message}` : `오류가 발생하였습니다.`) }
    }
    if (!opts.returnal){ 
      alert(`처리하였습니다.`)
      return initialize()
    }
    return result
  }

  // 선택한 내역으로 발주를 진행하는 기능
  actions.doCreateQuantities = async (items = selecteds, mode = '4u') => {
    if (!items.length) { return alert(`선택한 주문내역이 존재하지 않습니다.`) }

    if (!window.confirm(`현재 발주 처리한 내역(총 ${items.length}건)을 기준으로 엑셀을 다운로드 후 발주를 진행 할까요?`)) { return }
    await actions.doDownloadInvoiceExcel(items, mode).catch(e => null)
    
    const result = await items.reduce((prev, item, idx) => prev.then(async (report) => {
      const result = await actions.doCreateQuantity(item, { returnal: true }).catch(e => null)
      if (result && result.error) {
        report.failure.push({ error: true, message: (result && result.message) ? `${result.message}` : `오류가 발생하였습니다.` })
        return report
      }
      report.success.push({ error: false, message: (result && result.message) ? `${result.message}` : `정상적으로 처리되었습니다.` })
      return report
    }), Promise.resolve({ success: [], failure: [] }))

    if (result.failure.length) { alert(`성공 ${result.success.length}건, 실패 ${result.failure.length}건이 발생하였습니다.`) }
    if (!result.failure.length) { alert(`${result.success.length}건이 성공적으로 처리하였습니다.`) }

    setProcessed(false)
    
    return initialize()
  }

  return (
    <Items>
      {progressModal ? <ProgressModal {...progressModal} /> : null}
      
      <Task><strong>{comma(total)}개</strong>의 발주필요 상품이 검색되었습니다.</Task>
      <Header>
        <Header.Search>
          <div className="tools">
            {Object.keys(keywordsOptions).map(k => keywordsOptions[k]).map((o, index) =>
              <a
                key={`KeywordOption_${index}`}
                href={`#${o.name}`}
                className={o.name === keywordsOption ? "active" : ""}
                onClick={e => [e.preventDefault(), e.stopPropagation(), handle({ page: 1, keywordsOption: o.name }, () => initialize())]}
              >
                {o.text}
              </a>
            )}
          </div>
          <div className="container">
            <input type="text"
              placeholder={title || '검색어를 입력해주세요.'} value={keywords}
              onChange={e => handle({ 'keywords': e.target.value })}
              onKeyUp={e => e.key === 'Enter' ? handle({ page: 1 }, () => initialize()) : null}
            />
          </div>
        </Header.Search>
        <Header.Options>
          <a
            href="#rollback-invoices"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doRollbackInvoices()
                .finally(() => setPending(false))
            }}>
              비우기 🧹
          </a>
          <a
            href="#doRefreshInvoices"
            className="danger"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()
              return await actions.doRefreshInvoices()
                .finally(() => setPending(false))
            }}>
              자동발주 내역 재생성 🔥
          </a>
          {/* <a
            href="#doCreateQuantitiesBySelecteds-4u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              if (processed) { return alert(`현재 진행중입니다. 잠시만 기다려주세요.`) }
              setProcessed(true)

              return await actions.doCreateQuantities(selecteds, '4u')
            }}>
              [4U] 선택 발주진행+엑셀 📜
          </a> */}
          <a
            href="#doCreateQuantitiesBySelecteds-2u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              if (processed) { return alert(`현재 진행중입니다. 잠시만 기다려주세요.`) }
              setProcessed(true)

              return await actions.doCreateQuantities(selecteds, '2u')
            }}>
              [2U] 선택 발주진행+엑셀 📜
          </a>
          {/* <a
            href="#doCreateQuantities-4u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              if (processed) { return alert(`현재 진행중입니다. 잠시만 기다려주세요.`) }
              setProcessed(true)

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doCreateQuantities(items, '4u')
            }}>
              [4U] 전체 발주진행+엑셀 📜
          </a> */}
          <a
            href="#doCreateQuantities-2u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              if (processed) { return alert(`현재 진행중입니다. 잠시만 기다려주세요.`) }
              setProcessed(true)

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doCreateQuantities(items, '2u')
            }}>
              [2U] 전체 발주진행+엑셀 📜
          </a>
        </Header.Options>
      </Header>

      <Header>
        <Header.Options>
          <a
            href="#doCreateLegalDepositPayBySelecteds"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doCreateLegalDepositPayBySelecteds()
            }}>
              선택 납본발주 생성
          </a>
          <a
            href="#doCreateInvoiceByBookNo"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doCreateInvoiceByBookNo()
            }}>
              도서번호 수기발주 생성
          </a>
          {/* <a
            href="#doDownloadPrintFileExcel-4u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doDownloadPrintFileExcel(selecteds, '4u')
            }}>
              [4U] 선택 원고엑셀 📖
          </a> */}
          <a
            href="#doDownloadPrintFileExcel-2u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doDownloadPrintFileExcel(selecteds, '2u')
            }}>
              [2U] 선택 원고엑셀 📖
          </a>
          {/* <a
            href="#doDownloadInvoiceExcel-4u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doDownloadInvoiceExcel(selecteds, '4u')
            }}>
              [4U] 선택 발주엑셀 📜
          </a> */}
          <a
            href="#doDownloadInvoiceExcel-2u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              return await actions.doDownloadInvoiceExcel(selecteds, '2u')
            }}>
              [2U] 선택 발주엑셀 📜
          </a>
          {/* <a
            href="#doDownloadPrintFileExcel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doDownloadPrintFileExcel(items, '4u')
            }}>
              [4U] 전체 원고엑셀 📖
          </a> */}
          <a
            href="#doDownloadPrintFileExcel"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doDownloadPrintFileExcel(items, '2u')
            }}>
              [2U] 전체 원고엑셀 📖
          </a>
          {/* <a
            href="#doDownloadInvoiceExcel-4u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doDownloadInvoiceExcel(items, '4u')
            }}>
              [4U] 전체 발주엑셀 📜
          </a> */}
          <a
            href="#doDownloadInvoiceExcel-2u"
            onClick={async (e) => {
              e.stopPropagation()
              e.preventDefault()

              const items = await actions.getItemsAll({}, { progress: true })
              return await actions.doDownloadInvoiceExcel(items, '2u')
            }}>
              [2U] 전체 발주엑셀 📜
          </a>
        </Header.Options>
      </Header>

      <Items.Tools>
        {Object.keys(filterOptions)
          .filter(key => filterOptions[key] && filterOptions[key].type === 'tool')
          .map(key => {
          const filterOption = filterOptions[key]
          return (
            <Dropdown
              key={filterOption.name}
              header={filterOption.text}
              items={filterOption.conditions}
              current={filters[filterOption.name]}
              onActive={item => {
                if (filterOption.multiple) {
                  let value = filters[filterOption.name] ? filters[filterOption.name].split(',') : []
                  if (value.includes(item.name)) {
                    value = value.filter(v => item.name !== v)
                    if (!value.length) { value = ['all'] }
                  } else {
                    if (item.name === 'all') {
                      value = ['all']
                    } else {
                      value.push(item.name)
                      value = value.filter(v => v !== 'all')
                    }
                  }
                  return handle({ page: 1, filters: { ...filters, [filterOption.name]: value.join(',') } }, () => initialize())
                }
                return handle({ page: 1, filters: { ...filters, [filterOption.name]: item.name } }, () => initialize())
              }}
              usedAll
              {...commonProps}
            />
          )
        })}
        {/* {Object.keys(sortOptions).length ? (
          <Sorts
            header={'정렬'}
            items={Object.keys(sortOptions).map(key => sortOptions[key])}
            current={sorts}
            onActive={(sort) => {
              if (!sorts || !sorts.length) { return handle({ page: 1, sorts: [sort.name] }) }
              const prev = [...sorts]
              const keys = [sort.name, `-${sort.name}`]
              if (!prev.includes(keys[0]) && !prev.includes(keys[1])) {
                prev.push(keys[0])
                return handle({ sorts: prev })
              }
              const next = prev.map((sortKey) => {
                if (!keys.includes(sortKey)) { return sortKey }
                return keys[0] === sortKey ? keys[1] : keys[0]
              })
              handle({ sorts: next }, () => initialize())
            }}
            onCancel={(sort) => {
              if (!sorts || !sorts.length || sorts.length === 1) { return }
              const keys = [sort.name, `-${sort.name}`]
              const next = [...sorts].filter((sortKey) => !keys.includes(sortKey))
              handle({ sorts: next }, () => initialize())
            }}
            {...commonProps}
            style={{ maxWidth: '120px' }}
          />
        ) : null} */}
      </Items.Tools>

      {items.length ? (
        <Items.Body>

          <DataSet>
            <DataSet.Header>
              <DataSet.Item>
                {columns.map((column, index) => <DataSet.Col key={`DataSet_Header_Col_${index}`} style={column.style}>{column.header}</DataSet.Col>)}
              </DataSet.Item>
            </DataSet.Header>
            <DataSet.Body>
              {items.map((item, itemIdx) => (
                <DataSet.Item key={item.id}>
                  {columns.map((column, columIdx) => {
                    // const columnProps = { column, columIdx, item, loadItems }

                    const extras = item.extras || {}
                    const currentProduct = extras.currentProduct || {}
                    const currentStock = extras.currentStock || {}
                    const readyParcelOrderAmount = extras.readyParcelOrderAmount || 0

                    const meta = {}

                    meta.manual = item.manual ? `수` : `자` // 자동발주여부, 수동발주여부
                    meta.bookNo = _.get(currentProduct, 'content.bookNo')
                    meta.bookTitle = _.get(currentProduct, 'content.title') || _.get(currentProduct, 'name')
                    meta.bookAuthor = _.get(currentProduct, 'content.author')
                    meta.bookIsbn = _.get(currentProduct, 'content.isbn') || _.get(currentProduct, 'content.retailIsbns.bookk.isbn') || ''
                    meta.userId = _.get(currentProduct, 'user')
                    meta.bookPurpose = _.get(currentProduct, 'content.purpose') || { text: '알수없음' }
                    meta.isbn = _.get(currentProduct, 'content.isbn') || ''
                    meta.publisher = _.get(currentProduct, 'content.publisher') || ''

                    meta.bookTextColor = _.get(currentProduct, 'content.text.color.text') || '알수없음'

                    meta.readyParcelOrderAmount = readyParcelOrderAmount
                    meta.enabledStockAmount = (_.get(currentStock, 'instock') || 0) + (_.get(currentStock, 'overflow') || 0)
                    meta.invoiceStockAmount = (_.get(currentStock, 'invoice') || 0)
                    meta.amount = (_.get(item, 'amount') || 0)
                    meta.restAmount = meta.enabledStockAmount - meta.amount
                    meta.editAmount = _.get(item, 'editAmount')

                    // 납본상태에 대한 구분
                    meta.isExternal = ['external'].includes(_.get(meta, 'bookPurpose.name'))
                    meta.legalStatus = meta.isExternal ? _.get(currentProduct, 'content.legalDeposit.status') : 'none'

                    return (
                      <DataSet.Col key={`${column.key}_${columIdx}`} style={column.style}>
                        {(key => {
                          switch (key) {
                            case 'checkbox':
                              return (
                                <>
                                  <input type="checkbox" checked={isExistsSelected(item)} readOnly onClick={e => [e.stopPropagation(), doCheck(item)]} />
                                </>
                              )
                            case 'TypeColumn':
                              return (
                                <div>
                                  <strong>{meta.manual}</strong>
                                </div>
                              )
                            case 'NoColumn':
                              return (
                                <div
                                onClick={(e) => {
                                  const productType = _.get(currentProduct, 'productType')
                                  return window.open(`/products/${productType}/${_.get(currentProduct, 'id')}`)
                                }}
                                style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                >
                                  <strong>{meta.bookNo}</strong>
                                </div>
                              )
                            case 'DetailColumn':
                              return (
                                <div style={{ minWidth: '100%', maxWidth: '100%' }}>
                                  <div
                                  onClick={(e) => {
                                    const productType = _.get(currentProduct, 'productType')
                                    return window.open(`/products/${productType}/${_.get(currentProduct, 'id')}`)
                                  }}
                                  style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                  >
                                    <strong>{meta.bookTitle}</strong>
                                  </div>
                                  <div
                                    onClick={(e) => {
                                      return window.open(`/users/${meta.userId}`)
                                    }}
                                    style={{ cursor: 'pointer', maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                  >
                                    <small>{meta.bookAuthor}</small>
                                  </div>
                                </div>
                              )
                            case 'TextColorColumn':
                              return (
                                <div style={{ textOverflow: 'hidden', whiteSpace: 'normal', textAlign: 'center' }}>
                                  <strong>{_.get(meta, 'bookTextColor')}</strong>
                                </div>
                              )
                            case 'PurposeColumn':
                              return (
                                <div style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}>
                                  <strong>{_.get(meta, 'bookPurpose.text')}</strong>
                                  {['external'].includes(_.get(meta, 'bookPurpose.name'))
                                    ? (
                                      <div>
                                        <small>{_.get(meta, 'isbn') || 'ISBN 미기재'}</small>
                                      </div>
                                    )
                                    : null}
                                </div>
                              )
                            case 'LegalColumn':
                              return (
                                <div
                                  style={{ maxWidth: 'fit-content', whiteSpace: 'normal' }}
                                  title={_.get(currentProduct, 'content.legalDeposit.status')}
                                >
                                  {['required'].includes(meta.legalStatus) ? <strong>납본필요 🔴</strong> : null}
                                  {['resolved'].includes(meta.legalStatus) ? <strong>납본완료 🙂</strong> : null}
                                  {['rejected'].includes(meta.legalStatus) ? <strong>납본취소 🟣</strong> : null}
                                  {['none'].includes(meta.legalStatus) ? <strong>해당없음 ⚪</strong> : null}
                                  <div>
                                    <small>{meta.publisher}</small>
                                  </div>
                                </div>
                              )
                            case 'CreatedAtColumn':
                              return (
                                <div>
                                  <strong>{meta.createdMt ? meta.createdMt.format('YYYY.MM.DD') : '0000.00.00'}</strong>
                                  <small style={{ marginLeft: '0.35rem' }}>{meta.createdMt ? meta.createdMt.format('HH:mm') : '00:00'}</small>
                                </div>
                              )

                            case 'ReadyParcelOrderAmountColumn':
                              return (
                                <div>
                                  <strong>{comma(_.get(meta, 'readyParcelOrderAmount'))}</strong>
                                  <small style={{ marginLeft: '0.25rem' }}>개</small>
                                </div>
                              )
                            case 'EnabledStockAmountColumn':
                              return (
                                <div>
                                  <strong>{comma(_.get(meta, 'enabledStockAmount'))}</strong>
                                  <small style={{ marginLeft: '0.25rem' }}>개</small>
                                </div>
                              )
                            case 'InvoiceStockAmountColumn':
                              return (
                                <div>
                                  <strong>{comma(_.get(meta, 'invoiceStockAmount'))}</strong>
                                  <small style={{ marginLeft: '0.25rem' }}>개</small>
                                </div>
                              )
                            case 'AmountColumn':
                              return (
                                <div>
                                  <strong>{comma(_.get(meta, 'amount'))}</strong>
                                  <small style={{ marginLeft: '0.25rem' }}>개</small>
                                </div>
                              )

                            case 'ActionColumn':
                              return (
                                <div style={{ display: 'flex' }}>
                                  <input
                                    type="number"
                                    style={{ textAlign: 'right', marginRight: '0.35rem' }}
                                    value={meta.editAmount}
                                    onChange={e => {
                                      const value = _.isInteger(e.target.value * 1) ? e.target.value * 1 : 0
                                      const next ={}
                                      next[`items[${itemIdx}].editAmount`] = value
                                      return handle(next)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doUpdateInvoiceAmount(item)]
                                    }}
                                  >
                                    변경
                                  </button>
                                </div>
                              )
                              
                            case 'ControlColumn':
                              return (
                                <div>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={e => {
                                      return [e.stopPropagation(), e.preventDefault(), actions.doDeleteInvoice(item)]
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )
                            default:
                          }
                        })(column.key)}
                      </DataSet.Col>
                    )
                  })}
                </DataSet.Item>
              ))}
            </DataSet.Body>
          </DataSet>

        </Items.Body>
      ) : null}
      
      {!items.length ? (
        <Items.NotFound>
          <header>데이터가 존재하지 않습니다.</header>
          <section>자동발주로 필요한 상품(발주 필요량 존재 상품)이 존재하지 않습니다.</section>
        </Items.NotFound>
      ) : null}

      <Items.Footer>
        <Pager total={total} page={page} limit={limit} blockLimit={blockLimit} setPage={page => handle({ page }, () => initialize())} />
      </Items.Footer>

    </Items>
  )
}

List.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.any,

  more: PropTypes.func,

  keywordsOption: PropTypes.string,
  keywords: PropTypes.string,
  filters: PropTypes.object,
  sorts: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  selecteds: PropTypes.arrayOf(PropTypes.object),

  total: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  blockLimit: PropTypes.number,

  startAt: PropTypes.any,
  endAt: PropTypes.any,

  filterOptions: PropTypes.object,
  sortOptions: PropTypes.object,
  keywordsOptions: PropTypes.object,

  title: PropTypes.string,
  nav: PropTypes.string
}

List.defaultProps = {
  user: {},
  location: {},
  history: {},
  match: {},
  error: null,

  more: null,

  keywordsOption: '',
  keywords: '',
  filters: {},
  sorts: [],
  items: [],
  selecteds: [],

  page: 1,
  limit: 500,
  blockLimit: 5,

  startAt: null,
  endAt: null,

  filterOptions: {},
  sortOptions: {},
  keywordsOptions: {},

  title: '발주내역',
  nav: 'working'
}

export default List
