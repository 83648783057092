import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import api from 'services/api'

import styled from 'styled-components'
import { font, palette } from 'styled-theme'

import { comma } from 'services/utils'

const Column = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%;  max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;

  & a:hover { color: ${palette('primary', 15)}; transition: all 0.3s; }
  & a:hover strong { color: ${palette('primary', 7)} !important; transition: all 0.3s; }
`

const Meta = styled.div`
  position: relative; box-sizing: border-box;
  flex: 1 1 100%; width: 50%; min-width: 50%; max-width: 50%; margin-bottom: 0.35rem;
  & > header {
    position: relative; box-sizing: border-box;
    font-size: 0.85em;
  }
  & > section {
    position: relative; box-sizing: border-box;
  }
`

Meta.Group = styled.div`
  position: relative; box-sizing: border-box;
  width: 100%; min-width: 100%; max-width: 100%; overflow: hidden;
  white-space: normal; word-break: break-all;
  display: flex; flex-wrap: wrap;
`

class ComplexDetailColumnContainer extends React.Component {
  constructor(props) {
    super(props)
    this.abortController = new AbortController()

    this.state = { datas: [] }

    this.initialize = this.initialize.bind(this)
  }

  async initialize() {
    // const output = await api.get(`/approvals/admin2/${this.props.nav}?${qs.stringify(query)}`, { signal: this.abortController.signal })
    //   .then(({ count, rows }) => ({ rows, count, error: false }))
    //   .catch(e => ({ rows: [], count: 0, error: e.message }))
    this.setState({ datas: [] })
  }
  
  render() {
    const { item } = this.props

    const meta = {
      bookType: _.get(item, 'content.type') || 'paperBook'
    }

    // 종이도서 설정정보
    if (meta.bookType === 'paperBook') {
      meta.bookTextPageCount = _.get(item, 'content.text.pageCount') || 0
      meta.bookSize = _.get(item, 'content.bookSize') || { name: 'none', text: '미지정' }
      meta.thickness = _.get(item, 'content.thickness') || 0
      meta.bookCoverMaterial = _.get(item, 'content.cover.material') || { name: 'none', text: '미지정' }
      meta.bookCoverFlap = _.get(item, 'content.cover.flap') || false
      meta.bookTextColor = _.get(item, 'content.text.color') || { name: 'none', text: '미지정' }
      meta.bookTextMaterial = _.get(item, 'content.text.material') || { name: 'none', text: '미지정' }
    }

    // 전자도서 설정정보
    if (meta.bookType === 'electronicBook') {
      meta.bookTextPageCount = _.get(item, 'content.text.pageCount') || 0
      meta.bookTextFileSize = _.get(item, 'content.text.file.size') || 0
      if (meta.bookTextFileSize > 0) { meta.bookTextFileSize = Math.round(meta.bookTextFileSize*100/1024)/100 }
      // 파일 정보의 확장자를 따서 대문자 씌워서 내보낸다.
      meta.ext = _.get(item, 'content.text.file.name') ? _.get(item, 'content.text.file.name') : ''
      if (meta.ext.indexOf('.') > 0 && meta.ext.length > 0) {
        meta.ext = meta.ext.split('.').reverse()[0]
        meta.ext = meta.ext.toUpperCase()
      } else {
        meta.ext = '알수없음'
      }
    }

    return (
      <Column>
        {meta.bookType === 'paperBook' ? (
          <>
            <Meta.Group>
              <Meta>
                <header>
                  <small>판형·표지</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.bookSize.text}</strong>
                  <small>{meta.bookCoverFlap ? `날개있음` : `날개없음`}</small>
                </section>
                <section>
                  <small>{meta.bookCoverMaterial.text}</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>색상·내지</small>
                </header>
                <section>
                  <strong>{meta.bookTextColor.text}</strong>
                </section>
                <section>
                  <small>{meta.bookTextMaterial.text}</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>페이지수</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.bookTextPageCount ? comma(meta.bookTextPageCount) : 0}</strong>
                  <small>Pages</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>책등</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.thickness}</strong>
                  <small>mm</small>
                </section>
              </Meta>
            </Meta.Group>
          </>
        ) : null}
        {meta.bookType === 'electronicBook' ? (
          <>
            <Meta.Group>
              <Meta>
                <header>
                  <small>형태</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.ext}</strong>
                </section>
              </Meta>
            </Meta.Group>
            <Meta.Group>
              <Meta>
                <header>
                  <small>페이지수</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.bookTextPageCount ? comma(meta.bookTextPageCount) : 0}</strong>
                  <small>Pages</small>
                </section>
              </Meta>
              <Meta>
                <header>
                  <small>용량</small>
                </header>
                <section>
                  <strong style={{ marginRight: '0.25rem' }}>{meta.bookTextFileSize ? comma(meta.bookTextFileSize) : 0}</strong>
                  <small>Kb</small>
                </section>
              </Meta>
            </Meta.Group>
          </>
        ) : null}
      </Column>
    )
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
}

ComplexDetailColumnContainer.propTypes = {
  item: PropTypes.object,
}

ComplexDetailColumnContainer.defaultProps = {
  item: {}
}

export default ComplexDetailColumnContainer
